<template>
  <div class="service">
    <div class="text">
      <div class="box">
        <img src="../../assets/images/404.png" alt="" class="pic">
        <span>找不到该页面啦~ </span>
        <div class="btom">
          <el-button type="primary" @click="back_page()">{{`${time}s后  返回上一页`}}</el-button>   
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      time:5,
      set_time:null
    }
  },
  mounted(){
    this.time_init()
  },
  beforeDestroy(){
    this.clear_time()
  },
  methods:{
    time_init(){
      this.set_time=setInterval(() => {
        if(this.time>0){
           --this.time
        }else{
          this.clear_time()
          this.back_page()
        }
      }, 1000);
    },
    clear_time(){
      clearInterval(this.set_time)
      this.set_time=null
    },
    back_page(){
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.service{
  height: 100vh;
  width: 100vw
}

.text{
  width: 100%;
  height: 100%;
  @include flex-center();
  .box{
    @include flex-column-left() ;
    // .pic{
    //   width: 400px;
    //   height: 400px;
    // }
    >span{
      padding: 30px 0 ;
      width: 100%;
      text-align: center
    }
    .btom{
      width: 100%;
      text-align: center
    }
  }
}
</style>